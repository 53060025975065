import { URLASSOLUTO, CURRENTROUTENAME } from "./variables";
import Swal from "./sweetalert2";
import { forEach } from "lodash";

$(document).ready(function () {
    // MENU MOBILE
    $("body").on("click", "#nav-icon1, #nav-icon2", function () {
        $.ajax({
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            url: URLASSOLUTO + "/menu-mobile/",
            method: "POST",
            data: { currentroutename: CURRENTROUTENAME },
            dataType: "html",
        }).done(function (result) {
            Swal.fire({
                background: "#fff",
                position: "center-end",
                grow: "fullscreen",
                showCloseButton: true,
                showConfirmButton: false,
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                },
                customClass: {
                    container: "menu-mobile",
                },
                html: result,
                onClose: function () {
                    $(".menuToggle input").prop("checked", false);
                },
            });
        });
    });

    // menu mobile categorie livello 1
    $("body").on("click", ".menu-row", function () {
        $(this).parent().toggleClass("selected");
        $(this).next().toggleClass("selected");

        let thisRow = $(this);

        // sottocategorie menu mobile
        let category_id = $(this).data("id");

        let totLi = $(thisRow).next().children().length;

        if (category_id && totLi == 0) {
            // spinner
            $(this).next().append(`
                <li class="spinnerMobile">
                    <i class="fas fa-spinner fa-spin"></i>
                </li>
            `);

            $.ajax({
                url: `${URLASSOLUTO}/menu-mobile/${category_id}`,
                method: "GET",
                dataType: "html",
            }).done(function (result) {
                $(".spinnerMobile").remove();

                let obj = JSON.parse(result);
                obj.map((value, key) => {
                    if (key == 0 && value.parent_category) {
                        $(thisRow).next().append(`
                        <li>
                            <a href="${URLASSOLUTO}/${value.parent_category.meta_data.urlseo_completo}">vai alla pagina ${value.parent_category.meta_data.titolo}</a>
                        </li>
                        `);
                    }
                    if (value.child_categories.length > 0) {
                        $(thisRow).next().append(`
                        <li class="menu-sub-row" data-id="${value.id}">
                            <a>${value.name}</a>
                        </li>
                        `);
                    } else {
                        $(thisRow).next().append(`
                        <li>
                            <a href="${URLASSOLUTO}/${value.meta_data.urlseo_completo}">${value.name}</a>
                        </li>
                        `);
                    }
                });
            });
        }
    });

    // menu mobile categorie livello 2
    $("body").on("click", ".menu-sub-row", function () {
        $(this).toggleClass("selected");

        let thisRow = $(this);

        // sottocategorie menu mobile
        let category_id = $(this).data("id");

        let totLi = $(thisRow).children().length;

        if (category_id && totLi == 1) {
            // spinner
            $(this).append(`
                <ul class="subSubNavMobile">
                    <li class="spinnerMobile">
                        <i class="fas fa-spinner fa-spin"></i>
                    </li>
                </ul>
            `);

            $.ajax({
                url: `${URLASSOLUTO}/menu-mobile/${category_id}`,
                method: "GET",
                dataType: "html",
            }).done(function (result) {
                $(".spinnerMobile").remove();

                let obj = JSON.parse(result);
                //console.log(obj);
                obj.map((value, key) => {
                    if (key == 0 && value.parent_category) {
                        $(thisRow).find(".subSubNavMobile").append(`
                        <li>
                            <a href="${URLASSOLUTO}/${value.parent_category.meta_data.urlseo_completo}">vai alla pagina ${value.parent_category.meta_data.titolo}</a>
                        </li>
                        `);
                    }
                    $(thisRow).find(".subSubNavMobile").append(`
                        <li>
                            <a href="${URLASSOLUTO}/${value.meta_data.urlseo_completo}">${value.name}</a>
                        </li>
                    `);
                });
            });
        }
    });

    // pulsante che apre form prodotto in versione mobile
    $("body").on("click", ".pulsanteForm", function () {
        // recupero form prodotto
        let thisFormProdotto = $(".form-prodotto");

        if (thisFormProdotto) {
            Swal.fire({
                background: "#fff",
                position: "center-end",
                grow: "fullscreen",
                showCloseButton: true,
                showConfirmButton: false,
                customClass: {
                    container: "menu-mobile form-prod",
                },
                html: "",
                onRender: function () {
                    console.log($("#swal2-content"));
                    $("#swal2-content")
                        .append(thisFormProdotto)
                        .css("display", "block");
                },
                onClose: function () {
                    $("#targetForm")
                        .append(thisFormProdotto)
                        .css("display", "none");
                },
            });
        }
    });
});
