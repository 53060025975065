const URLASSOLUTO = window.URLASSOLUTO;
const URLASSOLUTOISTITUZIONALE = window.URLASSOLUTOISTITUZIONALE;
const CURRENTROUTENAME = window.CURRENTROUTENAME;
const VIEWNAME = window.VIEWNAME;
delete window.URLASSOLUTO;
delete window.URLASSOLUTOISTITUZIONALE;
delete window.CURRENTROUTENAME;
delete window.VIEWNAME;
delete window.METATEMPLATEALTERNATE;

export { URLASSOLUTO, URLASSOLUTOISTITUZIONALE, CURRENTROUTENAME, VIEWNAME };
