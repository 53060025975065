import { URLASSOLUTO, URLASSOLUTOISTITUZIONALE } from "./variables";
import Swal from "./sweetalert2";
import bootstrapvalidation from "./validate";
//import './jquery.flexdatalist'; // messo script localmente perchè non funziona da node_modules...

$(document).ready(function () {
    let hash = window.location.hash;

    if ($(hash + "L").length) {
        $("html, body").animate(
            {
                scrollTop: $(hash + "L").offset().top,
            },
            1000
        );
    }
    if (hash === "#lavoraconnoi" && $(".bloccoformcontatti").length) {
        $("#tab2").attr("checked", true);
        $(".labellavoraconnoi").trigger("click");
        $("html, body").animate(
            {
                scrollTop: $(".bloccoformcontatti").offset().top,
            },
            1000
        );
    }

    // fade out background preloader
    $("#preload").fadeOut(function () {
        console.log("fadeout");
        /*$(".immagine-header.homepage").addClass('isvisible');*/
    });

    /*    $('body').on('click', '#nav-icon1,#nav-icon2,#nav-icon3,#nav-icon4', function () {
		$('.nav-icon-toggle').toggleClass('open');
        $('body').toggleClass('menuopen');
        $('.menuleft').toggleClass('open');
        $('.menuleft').find('nav').toggleClass('open');
	});
*/
    $(".sameheight").css("height", "auto");
    $(".sameheight").boxheight();
    $("body").on("change", ".languageselector", function () {
        var languageselected = $(this).find(":selected").data("path");
        if (typeof languageselected !== "undefined") {
            window.location.href = languageselected;
        }
    });
    $("body").on("change", ".applicationgroupselecter", function () {
        var applicationgroupselecter = $(this).find(":selected").data("path");
        if (typeof applicationgroupselecter !== "undefined") {
            window.location.href = applicationgroupselecter;
        }
    });
    $("body").on("click", ".calltoactionwwu", function () {
        $("#tab2").attr("checked", true);
        $(".labellavoraconnoi").trigger("click");
        $("html, body").animate(
            {
                scrollTop: $(".bloccoformcontatti").offset().top,
            },
            1000
        );
    });
});

$(window).resize(function () {
    $(".sameheight").css("height", "auto");
    $(".sameheight").boxheight();
});
