import Swal from 'sweetalert2';
window.Swal = Swal;

$(document).ready(function () {

    // funzione che chiede la conferma all'utente prima di cliccare il button
    $('.cancellaAskProdotto').on('submit', function (e, data) {

        /* console.log(e);
        */

        //console.log(data);

        // invio form
        if (data) {
            if (data.invia) return;
        }
        e.preventDefault();

        Swal.fire({
            title: "Sei sicuro?",
            html: `Questo prodotto verrà cancellato dal tuo preventivo`,
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Si, cancella!",
            cancelButtonText: 'Annulla'
        }).then(result => {
            /* console.log(result);
            console.log($(this)); */
            if (result.value == true) {
                //console.log('invio');
                $(this).trigger('submit', [{ 'invia': true }]);
            }
        });
    });

});

export { Swal as default };
