// Variables
const width = 2000;
const height = 190;
count = 100;
const rowsize = 15;
dotsize = 6;
dotmin = 1;
dotsizebase = 6;

// Calc
var canvases = document.querySelectorAll(".canvas");
canvases.forEach((canvas) => {
    var ctx = canvas.getContext("2d");

    ctx.canvas.color = canvas.getAttribute("data-color");
    ctx.canvas.colorrgba = canvas.getAttribute("data-colorrgba");
    ctx.canvas.width = width;
    ctx.canvas.height = height;
    mouseOver(canvas, ctx, false);
    canvas.addEventListener("mousemove", function () {
        mouseOver(canvas, ctx, true);
    });
    canvas.addEventListener("mouseleave", function () {
        mouseOver(canvas, ctx, false);
    });
});

function mouseOver(canvas, ctx, cursor) {
    if (cursor) {
        PosX = getPositionX(event);
        PosY = getPositionY(event);
    } else {
        PosX = -100;
        PosY = -100;
    }

    LocX = canvas.getBoundingClientRect().left;
    LocY = canvas.getBoundingClientRect().top;
    GlobalX = PosX - LocX;
    GlobalY = PosY - LocY;

    var ctx = canvas.getContext("2d");
    ctx.canvas.width = width;
    ctx.canvas.height = height;

    // The counter is used to calculate the color (fake random) if u want to have multiple canvas grids with a different color order just increase or decrease the value
    $counter = 5.56;
    for ($ix = 4; $ix <= count - 3; $ix++) {
        for ($iy = 4; $iy <= count - 3; $iy++) {
            ctx.beginPath();
            $scaler = Math.hypot(
                GlobalX / rowsize - $ix,
                GlobalY / rowsize - $iy
            );
            dotsize = dotsizebase - $scaler * 1.05;
            if (dotsize < dotmin) {
                dotsize = dotmin;
            }
            ctx.arc(rowsize * $ix, rowsize * $iy, dotsize, 0, 2 * Math.PI);
            $counter = $counter * 1.18;
            $nr = String($counter).charAt(2);

            ctx.strokeStyle = ctx.canvas.color;
            ctx.lineWidth = 1;
            ctx.fillStyle = `rgba(${ctx.canvas.colorrgba})`;
            ctx.fill();
        }
    }
}

//
function getPositionX(event) {
    CursorX = event.clientX;
    return CursorX;
}

function getPositionY(event) {
    CursorY = event.clientY;
    return CursorY;
}
