// import Swiper JS
import Swiper from "swiper";
// core version + navigation, pagination modules:
import SwiperCore, {
    Navigation,
    Pagination,
    Mousewheel,
    Parallax,
    Thumbs,
    Lazy,
} from "swiper/core";
// configure Swiper to use modules
SwiperCore.use([Navigation, Pagination, Mousewheel, Parallax, Thumbs, Lazy]);

// import Swiper styles
import "swiper/swiper-bundle.css";
import { URLASSOLUTO } from "./variables";

const swiper = $(document).ready(function () {
    var swiperg = new Swiper(".swiper-container.swiperg", {
        slidesPerView: 1,
        spaceBetween: 10,
        watchOverflow: true,
        navigation: {
            nextEl: ".swiper-button-next2",
            prevEl: ".swiper-button-prev2",
        },
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            // when window width is >= 640px
            768: {
                slidesPerView: "auto",
                spaceBetween: 40,
            },
        },
    });
    var swipers = new Swiper(".swiper-container.swipers", {
        slidesPerView: 1,
        spaceBetween: 10,
        watchOverflow: true,
        navigation: {
            nextEl: ".swiper-button-next2",
            prevEl: ".swiper-button-prev2",
        },
    });
});

export { swiper as default };
