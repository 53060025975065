import 'jquery-colorbox';

////import Swiper from 'swiper';
// core version + navigation, pagination modules:
////import SwiperCore, { Navigation, Pagination, Mousewheel, Parallax, Thumbs, Lazy } from 'swiper/core';
// configure Swiper to use modules
////SwiperCore.use([Navigation, Pagination, Mousewheel, Parallax, Thumbs, Lazy]);

// import Swiper styles
////import 'swiper/swiper-bundle.css'
import { URLASSOLUTO } from './variables';

const colorbox = $(document).ready(function() {
    $(".colorbox").colorbox({ maxWidth: "90%", maxHeight: "90%" });
    $(".colorboxtube").colorbox({ iframe: true, innerWidth: "90%", innerHeight: "90%", maxWidth: "90%" });
    $(".privacypop").colorbox({iframe: true, href: URLASSOLUTO + "/privacy-policy-iframe.html", innerWidth: '95%', innerHeight: 450 });
    $(".newsletterpop").colorbox({iframe: true, href: URLASSOLUTO + "/newsletter-policy-iframe.html", innerWidth: '95%', innerHeight: 450 });
    $(".cookiepop").colorbox({iframe: true, href: URLASSOLUTO + "/cookie-policy-iframe.html", innerWidth: '95%', innerHeight: 450 });
    $(".condizionivenditapop").colorbox({iframe: true, href: URLASSOLUTO + "/pdf/uploads/condizioni-di-vendita-attuali.pdf", innerWidth: '95%', innerHeight: 450 });
});

export { colorbox as default };
