const boxheight = (function ( $ ) {

    $.fn.boxheight = function( options ) {

        var altezze = new Array();

        this.each(function(){
            $(this).css('height','auto');
            let dataH = $(this).data("sameheight");
            let height = $(this).height();
            
            if( altezze[dataH] < height || typeof altezze[dataH] === "undefined") {
                altezze[dataH] = height;                
            }

        });
        
        $(altezze).each(function( index, value ) {
            if( typeof value === "undefined") { return; }
            $('*[data-sameheight="'+index+'"]').height(value);
        });
        
        return this;
    };

}( jQuery ));

export { boxheight as default };